/* src/LandingPage.css */
.layout {
    min-height: 100vh;
    background-color: #1890ff;
  }
  
  .content {
    padding: 50px;
    text-align: center;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
  }
  
  .pos-image {
    width: 100%;
    max-width: 800px;
    margin-bottom: 20px;
    border-radius: 8px;
  }
  
  .feature-images {
    display: flex;
    justify-content: space-around;
    margin: 30px 0;
  }
  
  .feature-images img {
    width: 300px;
    border-radius: 8px;
  }
  
  .footer {
    text-align: center;
    /* background-color: #001529; */
    /* color: #fff; */
  }
  

  /* main.css */

.pros-section {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    background-color: #f5f5f5;
  }
  
  .pros-cards {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .pro-card {
    flex: 1;
    margin: 0 8px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 16px;
    text-align: center;
  }
  